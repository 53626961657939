import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import {useAggsState} from "../../contexts/AggsContext";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useUploadsDispatch} from "../../contexts/UploadsContext";
import api from "../api";
import {
    Alert,
    Button,
    Card,
    Col,
    Collapse, Descriptions,
    Drawer,
    Dropdown,
    Menu,
    message, Modal,
    Popconfirm, Popover,
    Radio,
    Row,
    Select, Space, Tooltip,
    Typography
} from "antd";
import {
    CalendarOutlined,
    CheckCircleTwoTone, CheckOutlined,
    CloseCircleOutlined, CloudOutlined, CloudServerOutlined,
    DeleteOutlined,
    DownOutlined, DropboxOutlined, ExportOutlined, EyeOutlined,
    FileAddFilled, FileTextOutlined,
    LaptopOutlined, LoadingOutlined, MailOutlined, PhoneOutlined,
    SettingOutlined, TabletOutlined
} from "@ant-design/icons";
import {isBrowser, isMobile} from "device-detect";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import SetUploadDoneButton from "../uploads/SetUploadDoneButton";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useBulkJobsDispatch} from "../../contexts/BulkJobsContext";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";
import {useEditAssetsDispatch, useEditAssetsState} from "../../contexts/EditAssetsContext";
import {Formik, useFormikContext} from "formik";
import BulkJobProgress from "./BulkJobProgress";
import {Form, FormItem, Input} from "formik-antd";
import {Checkbox as FormikCheckbox} from "formik-antd/lib/checkbox";
import {Select as FormikSelect} from "formik-antd/lib/select";
import TagSelect from "../widgets/TagSelect";
import {Radio as FormikRadio} from "formik-antd/lib/radio";
import RightsPackage from "../widgets/RightsPackage";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import pluralize from "pluralize";
import useSelectAll from "../helpers/useSelectAll";
import {useViewMode} from "./ViewMenu";
import {RequiredTag, RequirementMetTag} from "../widgets/EditAssetTable";
import {SessionContext} from "../../contexts/SessionContext";
import TimeAgo from "react-timeago/lib";
import User from "../helpers/User";
import {useFilters} from "../helpers/useFilters";
import useCurrentOrg, {useRefreshOrg} from "../helpers/useCurrentOrg";
import {BoxIcon, FolderTreeIcon, FtpIcon, GoogleDriveIcon, RefreshIcon} from "../helpers/icons";
import FloatLabel from "../helpers/FloatLabel";
import {useAssetsDispatch} from "../../contexts/AssetsContext";
import useRefreshAssets from "../helpers/useRefreshAssets";
import moment from "moment";
import EventTagSelect from "../widgets/EventTagSelect";
import useCurrentUser from "../helpers/useCurrentUser";
import useDrivePicker from 'react-google-drive-picker'
import {useOrgPath} from "../helpers/OrgNavLink";
import HelpPopover from "../HelpPopover";
import VerticalSpace from "../helpers/VerticalSpace";
import {useTranslation} from "react-i18next";

export default ({quickUpload})=>{
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currentOrg = useCurrentOrg()

    const {state: appState, dispatch} = useContext(AppContext);
    const {currentUser} = appState;

    const {total} = useAggsState()
    const {selectedAssetIds} = useSelectedAssetsState();

    const {currentUpload: upload} = useAssetGroupState()
    const uploadsDispatch = useUploadsDispatch()

    const assetGroupDispatch = useAssetGroupDispatch()

    const onAddFiles = (e)=> {
        const files = Array.from(e.target.files)
        uploadsDispatch({type:'addFiles', files, upload: upload})
        assetGroupDispatch({type:'reloadContributionId', id: upload.contribution.id})
        e.target.value = '';
    }

    const getPath = useOrgPath()

    const cancelUpload = ()=> {
        api({
            url: `/api/uploads/${upload.id}`,
            method: 'delete'
        }).then(res => {
            if(res.data.empty) {
                message.success(t('message-upload-canceled','Upload Canceled.'));
                assetGroupDispatch({type:'reloadContributionId', id: upload.contribution.id})
                navigate(getPath('/explore'))
            }
        });
    }

    const [editDrawerVisible, setEditDrawerVisible] = useState()

    const {selectingAll, selectAll, selectNone} = useSelectAll()

    const {changeViewMode, viewMode} = useViewMode()

    useEffect(()=>{
        if(viewMode == 'table') {
            setActiveBulkKey('individual')
        }
    }, [viewMode])

    useEffect(()=> {
        if(upload) changeViewMode('grid')
    }, [upload?.id])

    const [activeBulkKey, setActiveBulkKey] = useState()

    const bulkCollapseChange = (val)=> {
        if(val == 'all') {
            if(!selectedAssetIds?.length) selectAll()
            setActiveBulkKey('all')
            changeViewMode('grid')
        } else if(val == 'individual') {
            selectNone()
            setActiveBulkKey('individual')
            changeViewMode('table')
        } else {
            if(activeBulkKey == 'individual') changeViewMode('grid')
            setActiveBulkKey(null)
        }
    }

    const clickBulkEditBtn = ()=> {
        if(!selectedAssetIds?.length) selectAll()
        changeViewMode('grid')
        setEditDrawerVisible(true)
    }

    const all = !selectedAssetIds?.length || selectedAssetIds?.length == total;

    const tagActionText = selectedAssetIds?.length === 1 ?
        t('tag-file', 'Tag 1 File') :
        t('tag-files', 'Tag {{count}} Files', {count: all ? 'All' : selectedAssetIds?.length})

    const editTitle = (
        <>
            {selectingAll ? <LoadingOutlined/> : <EditOutlined/> }
            &nbsp;{tagActionText}
            &nbsp;&nbsp;{!upload?.all_requirements_met && !isMobile() && <Typography.Text type={'danger'}>{t('view-requirements','View Requirements')}</Typography.Text>}
        </>
    )

    const {setFilters, filters} = useFilters()
    const changeDoneView = (e)=> {
        const val = e.target.value
        setFilters(true, {submitted: val == '' ? false : val})
    }

    const autoFocusInput = useRef(null);
    const [createFileVisible, setCreateFileVisible] = useState()
    const clickNewText = e => {
        setCreateFileVisible(true)
        setTimeout(()=>{
            autoFocusInput.current.focus()
        }, 100)
    }

    const assetsDispatch = useAssetsDispatch();
    const loadedAssetsDispatch = useLoadedAssetsDispatch()

    const refreshAssets = useRefreshAssets();

    const [ftpVisible, setFtpVisible] = useState()

    const hasDropbox = !!currentOrg?.dropbox_token
    const clickDropbox = useDropboxChooserButton()
    const clickConnectDropbox = useConnectDropboxButton(clickDropbox)

    const [boxVisible, setBoxVisible] = useState()
    const hasBox = !!currentOrg?.box_token
    const clickConnectBox = useConnectBoxButton(()=> setBoxVisible(true))

    const [googleDriveVisible, setGoogleDriveVisible] = useState(0)
    const hasGoogleDrive = !!currentOrg?.google_drive_access_token
    const clickConnectGoogleDrive = useConnectGoogleDrive(()=> setGoogleDriveVisible(true))

    const fileUploadRef = useRef()
    const dirUploadRef = useRef()

    if(!upload || !upload.contribution) return <></>

    if(upload?.done) return (
        <Row>
            <Col span={24}>
                <Card size={'small'} style={{marginTop:'1em'}}>
                    <CheckOutlined/> {t('upload-submitted','Upload submitted')} <TimeAgo date={upload.done_at}/>.
                </Card>
            </Col>
        </Row>
    )

    // ------------------------------------------------------------
    // Components
    // ------------------------------------------------------------

    const items = []
    items.push({
        key: 'upload',
        type: 'group',
        label: isMobile() ?  <> <TabletOutlined/> {t('your-device','Your Device')}</> : <><LaptopOutlined/> {t('your-computer','Your Computer')}</>,
        children: _.compact([{
            key: 'file-upload',
            onClick: ()=> fileUploadRef.current.click(),
            label: (
                <>
                    <label htmlFor="file-upload-button" style={{cursor: 'pointer'}}>
                        <FileAddFilled/> {isMobile() ? t('take-or-choose-photos', 'Take or Choose Photos') : t('add-files', 'Add Files')}
                    </label>
                </>
            )
        },
            isBrowser() ? {
                key: 'directory-upload',
                icon: <FolderTreeIcon/>,
                onClick: () => dirUploadRef.current.click(),
                label: (
                    <>
                        <label htmlFor="dir-upload-button" style={{cursor: 'pointer'}}>
                            {t('add-folder-tree', 'Add Folder Tree')}
                        </label>
                    </>
                )
            } : null,
            !!upload.ftp_username && !quickUpload ? {
                key: 'ftp',
                icon: <FtpIcon/>,
                label: <>{t('sftp-credentials','SFTP Credentials')}...</>,
                onClick: ()=> setFtpVisible(true),
            } : null
        ])
    }, !quickUpload ? {
        key: 'create-files',
        type:'group',
        label: <>{t('create-fields','Create Files')}</>,
        children: [
            {
                key: 'text',
                icon: <FileTextOutlined/>,
                label: t('text-file','Text File'),
                onClick: clickNewText
            }
        ]
    } : null, !quickUpload ? {
        key: 'integrations',
        type: 'group',
        label: <><CloudServerOutlined/> {t('integrations','Integrations')}</>,
        children: [
            {
                key: 'dropbox',
                icon: <DropboxOutlined/>,
                onClick: hasDropbox ? clickDropbox : clickConnectDropbox,
                label: <>{!hasDropbox && t('connect', 'Connect')} Dropbox</>
            },
            {
                key: 'box',
                icon: <BoxIcon/>,
                onClick: hasBox ? ()=> setBoxVisible(true) : clickConnectBox,
                label: <>{!hasBox && t('connect', 'Connect')} Box</>
            },
            {
                key: 'google-drive',
                icon: <GoogleDriveIcon style={{marginRight:8}}/>,
                onClick: hasGoogleDrive ? ()=> setGoogleDriveVisible(true) : clickConnectGoogleDrive,
                label: (
                    <>{!hasGoogleDrive && t('connect', 'Connect')} Google Drive</>
                )
            },
        ]
    } : null)

    const ChooseFiles = upload.user_id === currentUser?.id && (
        <>
            <Dropdown
                trigger={isMobile() ? 'click' : ['hover', 'click']}
                menu={{items}}
            >
                <Button
                    size={quickUpload ? 'large' : 'small'}
                    block={quickUpload || isMobile()}
                    ghost
                    type={'primary'}
                >
                    <UploadOutlined/> {isMobile() ? t('take-or-choose-photos', 'Take or Choose Photos') : t('choose-files', 'Choose Files')} <DownOutlined/>
                </Button>
            </Dropdown>

            <input ref={dirUploadRef} name={'dir-upload-button'} type={'file'} multiple onChange={onAddFiles} style={{display: 'none'}} webkitdirectory='true' directory='true'/>
            <input ref={fileUploadRef} name={'file-upload-button'} type={'file'} multiple onChange={onAddFiles} style={{display: 'none'}}/>

            {!quickUpload && (
                <>
                    <GoogleDrivePicker visible={googleDriveVisible}/>

                    <BoxFilePicker visible={boxVisible} onCancel={() => setBoxVisible(false)}/>

                    <FTPButton upload={upload} menuItem visible={ftpVisible} setVisible={setFtpVisible}/>
                </>
            )}
        </>
    )

    const CancelButton = upload.contribution.asset_group?.type != 'StorageFolder' && (
        <>
            <Popconfirm
                title={t('confirm-cancel-upload', 'Cancel Upload and Delete Files?')}
                onConfirm={() => cancelUpload()}
            >
                <Button
                    style={{marginLeft:'1em'}}
                    size={'small'}
                    danger
                    ghost
                    block={isMobile()}
                >
                    <DeleteOutlined /> {t('button-cancel','Cancel')}
                </Button>
            </Popconfirm>
        </>
    )

    const onUpdateGuest = field => {
        return value => {
            const data = {user: {}};
            data.user[field] = value;
            api.put('/api/update_guest', data).then(res => {
                if(res.data.error) {
                    return message.error(res.data.error)
                }
                message.success(t('message-updated','Updated!'))
                dispatch({type:'user_updated', user: res.data})
            })
        }
    }

    const NameAndEmail = (
        <>
            {upload.user_id == currentUser?.id && currentUser?.guest && (
                <Descriptions bordered size={'small'} column={1} style={{width:'100%', margin:'1em 0'}}>
                    <Descriptions.Item label={t('first-name','First Name')} labelStyle={{width:150}}>
                        <Typography.Text editable={{onChange: onUpdateGuest('first_name')}}>{currentUser.first_name}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('last-name','Last Name')}>
                        <Typography.Text editable={{onChange: onUpdateGuest('last_name')}}>{currentUser.last_name}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('email','Email')}>
                        <Typography.Text editable={{onChange: onUpdateGuest('guest_email')}}>{currentUser.guest_email}</Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
            )}
        </>
    )

    const MobileSteps = isMobile() && (
        <Card
            size={'small'}
            id={'mobile-upload-steps'}
            title={
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <h3>{upload.contribution.name}</h3>
                    <Button size='small' type={'text'} onClick={refreshAssets}>
                        <RefreshIcon/>
                    </Button>
                </div>
            }
        >
            {!!upload.contribution.text?.length && (
                <p>{upload.contribution.text}</p>
            )}

            {NameAndEmail}

            <Collapse defaultActiveKey={'choose'}>
                <Collapse.Panel
                    key={'choose'}
                    header={<>1. {t('choose','Choose')}</>}
                >
                    {ChooseFiles}
                </Collapse.Panel>

                <Collapse.Panel
                    key={'tag'}
                    header={
                        <>
                            2. <Typography.Text type={total > 0 && !upload.all_requirements_met && 'danger'}>{t('tag','Tag')}</Typography.Text>
                        </>
                    }
                >
                    <>
                        <Button
                            icon={<EditOutlined/>}
                            onClick={clickBulkEditBtn}
                            loading={selectingAll}
                            block
                            ghost
                            type={'primary'}
                            disabled={!total}
                        >
                            {
                                selectedAssetIds.length ?
                                    (selectedAssetIds.length == 1 ? t(`add-tags-to-file`, 'Add Tags To 1 File') : t(`add-tags-to-files`, 'Add Tags To {{count}} Files')) :
                                    t(`add-tags-to-all-files`, 'Add Tags To All Files')
                            }
                        </Button>
                        {viewMode == 'grid' && (
                            <Button
                                icon={<EditOutlined/>}
                                onClick={()=> changeViewMode('table')}
                                block
                                style={{marginTop:'.5em'}}
                                disabled={!total}
                            >
                                {t('button-tag-individually','Tag Individually')}
                            </Button>
                        )}
                        <Drawer
                            title={editTitle}
                            placement={'left'}
                            width={'100vw'}
                            open={editDrawerVisible}
                            onClose={() => setEditDrawerVisible(false)}
                            bodyStyle={{padding: 0, margin: '.5em .5em 0 .5em'}}
                        >
                            <EditTableBulkForm onDone={()=> setEditDrawerVisible(false)}/>
                        </Drawer>
                    </>
                </Collapse.Panel>

                {upload.contribution.asset_group?.type != 'StorageFolder' && (
                    <Collapse.Panel
                        key={'submit'}
                        header={
                            <>
                                3. <Typography.Text type={!upload.done && 'danger'}>{t('submit','Submit')}</Typography.Text>
                            </>
                        }
                    >
                        <Space style={{width:'100%'}}>
                            {CancelButton}
                            <SetUploadDoneButton/>
                        </Space>
                    </Collapse.Panel>
                )}
            </Collapse>
        </Card>
    )

    if(quickUpload) {
        return (
            <div style={{width: '100%'}}>
                {isMobile() ? (
                    <>
                        <Button icon={<FileAddFilled/>} block ghost type={'primary'} onClick={()=> fileUploadRef.current.click()}>
                            {t('take-or-choose-photos', 'Take or Choose Photos')}
                        </Button>

                        <input ref={fileUploadRef} name={'file-upload-button'} type={'file'} multiple onChange={onAddFiles} style={{display: 'none'}}/>
                    </>
                ) : ChooseFiles
            }</div>
        )
    }

    return (
        (<Row style={{padding: isMobile() && '1em', paddingRight: '1em'}}>
            <Formik
                initialValues={{}}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    if(!values.filename.match(/\.(txt|csv|md)$/)) {
                        message.error(t('error-please-include-text-file-extension','Please include a text file extension, e.g. .txt, .csv, .md'))
                        return actions.setSubmitting(false)
                    }

                    values.content = values.content || ''

                    api.post('/api/assets', {asset: values, upload_id: upload.guid}).then(res => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        autoFocusInput.current.focus()
                        message.success(t('message-asset-created','Asset Created!'))
                        assetsDispatch({type: 'assetsAdded', assets: [res.data]});
                        loadedAssetsDispatch({type: 'assetsAdded', assets: [res.data]}); // IDs only
                    })
                }}
            >
                {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                    return (
                        (<Modal
                            title={<><FileTextOutlined/> Create Text File</>}
                            open={createFileVisible}
                            onCancel={()=> setCreateFileVisible(false)}
                            centered
                            confirmLoading
                            footer={
                                <Space direction={'horizontal'}>
                                    <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                        <SaveOutlined/>
                                        {t('button-create','Create')}
                                    </Button>
                                    <Button onClick={() => setCreateFileVisible(false)}>{t('button-cancel','Cancel')}</Button>
                                </Space>
                            }
                        >
                            <form onSubmit={handleSubmit}>
                                <FormItem required name='filename' showValidateSuccess>
                                    <FloatLabel label={t('filename','Filename')} name={'filename'} value={values?.filename}
                                                description={t('text-filename-description','e.g. new-file.txt')}>
                                        <Input size={'large'} required name='filename' ref={autoFocusInput} autoFocus autoComplete='off'/>
                                    </FloatLabel>
                                </FormItem>

                                <FormItem name='content' style={{marginTop:'.5em'}}>
                                    <FloatLabel label={t('content','Content')} name={'content'} value={values?.content} description={t('optional','Optional.')}>
                                        <Input.TextArea rows={5} name='content'/>
                                    </FloatLabel>
                                </FormItem>
                            </form>
                        </Modal>)
                    );
                }}
            </Formik>
            <Col span={24}>
                {MobileSteps}

                {isBrowser() && (
                    <>
                        {currentUser?.id != upload.user?.id && (
                            <div style={{margin:'1em 0'}}>
                                <Typography.Text type={'secondary'}>
                                    <em>
                                        {t('created','Created')} <TimeAgo date={upload.created_at}/> {t('by','by')} <User user={upload.user}/>
                                        &nbsp;
                                        <a href={encodeURI(`mailto:${upload.user.email}?subject=${t('email-your-mediagraph-upload-session','Your Mediagraph Upload Session')}&body=${t('email-upload-missing-tags','One or more files from this upload are missing required tags. Please click the link to review and complete the requirements')}: ${location.href}`)}><MailOutlined/> Email</a>
                                    </em>
                                </Typography.Text>
                            </div>
                        )}

                        {NameAndEmail}

                        {!!upload.contribution.text?.length && (
                            <Card style={{margin: isBrowser() ? '1em 0' : '0 0 1em'}} size={'small'}>
                                {upload.contribution.text}
                            </Card>
                        )}
                        <div style={{marginTop:'.5em', textAlign: isMobile() && 'center'}}>
                            {ChooseFiles}

                            {CancelButton}

                            {isBrowser() && <SetUploadDoneButton/>}

                            {isBrowser() && (
                                <div style={isBrowser() ? {marginLeft:'1em', display:'inline', float:'right'} : {marginTop: '1em', textAlign: 'center'}}>
                                    <Radio.Group size={'small'} onChange={changeDoneView} value={filters.submitted || 'all'}>
                                        <Radio.Button value={'all'}><EyeOutlined/> {t('all','All')}</Radio.Button>
                                        <Radio.Button value={'false'}>{t('un-submitted','Un-Submitted')}</Radio.Button>
                                        <Radio.Button value={'true'}>{t('submitted','Submitted')}</Radio.Button>
                                    </Radio.Group>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Col>
            {isBrowser() && (
                <Col span={24} style={{marginTop:'1em'}}>
                    <Collapse
                        onChange={bulkCollapseChange}
                        activeKey={activeBulkKey}
                        accordion
                    >
                        <Collapse.Panel
                            key={'all'}
                            header={editTitle}
                            id={'tag-all-collapse-panel'}
                        >
                            <EditTableBulkForm
                                onDone={()=>{
                                    setActiveBulkKey(null)
                                }}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel
                            key={'individual'}
                            header={t('tag-individually','Tag Individually')}
                            id={'tag-individually-collapse-panel'}
                        >
                            <em>{t('select-and-tag-files-below','Select and Tag Files Below')}</em>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            )}
        </Row>)
    );
}

const EditTableBulkForm = ({onDone})=> {
    const {t} = useTranslation();
    const {state} = useContext(AppContext);
    const {currentUser} = state;

    const {selectedAssetIds} = useSelectedAssetsState();
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const [bulkJobId, setBulkJobId] = useState()
    const bulkJobDispatch = useBulkJobsDispatch()

    const loadedAssetsDispatch = useLoadedAssetsDispatch();
    const editAssetDispatch = useEditAssetsDispatch()

    const assetGroupDispatch = useAssetGroupDispatch()

    const {currentUpload: upload} = useAssetGroupState()

    const [uploadAttrs, setUploadAttrs] = useState({})
    useEffect(()=>{
        if(upload) setUploadAttrs(upload)
    }, [upload?.id])

    const refreshUpload = ()=>{
        if(!upload) return

        console.log('refreshing upload')
        api(`/api/uploads/${upload.id}`).then(res => {
            assetGroupDispatch({type: 'setCurrentUpload', upload: res.data});
        })
    }

    const {assetEdits} = useEditAssetsState()
    useEffect(()=>{
        refreshUpload()
    }, [assetEdits])

    const onChangeUpload = (field)=>{
        return (value)=> {
            const data = {}
            data[field] = value
            uploadAttrs[field] = value
            setUploadAttrs({...uploadAttrs})
            api.put(`/api/uploads/${upload.id}`, {upload:data}).then(res => {
                assetGroupDispatch({type: 'setCurrentUpload', upload: res.data});
                message.success(`${field} ${t('updated','updated')}.`)
            })
        }
    }

    return (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                values.asset_ids = values.ids || selectedAssetIds;

                api.post(`/api/bulk_jobs`, {bulk_job: values}).then(res => {
                    actions.resetForm();
                    actions.setSubmitting(false);

                    setBulkJobId(res.data.guid)
                    bulkJobDispatch({type:'add', bulkJob: res.data})

                }).catch((err)=>{
                    console.log(err)
                })
            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {

                const {selectAll} = useSelectAll()
                const clickSubmit = ()=> {
                    if(selectedAssetIds.length) {
                        submitForm()
                    } else {
                        selectAll((ids)=>{
                            setFieldValue('ids', ids)
                            setTimeout(()=>{
                                submitForm()
                            }, 10)
                        })
                    }
                }

                const onFinish = (bj)=> {
                    onDone && onDone()
                    message.success(t('message-bulk-edit-finished',`Bulk Edit finished!`))
                    loadedAssetsDispatch({type:'reload'})
                    upload && editAssetDispatch({type:'increment'})
                    setBulkJobId(null)

                    selectedAssetDispatch({type:'reload'})

                    refreshUpload()
                }

                const [creatorTagsLoading, setCreatorTagsLoading] = useState(false)
                const defaultTags = [
                    { name: 'Unknown', id: 'unknown' }
                ]
                const [creatorTags, setCreatorTags] = useState(defaultTags)

                const [newCreatorTag, setNewCreatorTag] = useState()

                const creatorTagOptions = creatorTags.map(c => <Select.Option key={c.name}>{c.name}</Select.Option>);

                if(newCreatorTag && !_.find(creatorTags, {name: newCreatorTag})) {
                    creatorTagOptions.unshift(
                        <Select.Option key={newCreatorTag}>{newCreatorTag}</Select.Option>
                    )
                }

                const onCreatorSearch = (val)=> {
                    setCreatorTagsLoading(true)
                    setNewCreatorTag(val)

                    api(`/api/creator_tags?q=${val}`).then(res => {
                        setCreatorTagsLoading(false)
                        setCreatorTags(res.data)
                    })
                }

                const requirementTag = (field) => {
                    return (
                        <span style={{marginLeft:'.5em'}}>
                            {upload[`${field}_met`] ? <RequirementMetTag/> : <RequiredTag/>}
                        </span>
                    )
                }

                const CreatorTitle = (
                    <strong>Creator {upload.contribution.require_creator_tag && requirementTag('creator_tags')}</strong>
                )

                const CreatorForm = (
                    <FormItem name={'creator_tag_name'}>
                        <FormikCheckbox name={'set_creator_as_me'}>{t('me','Me')}</FormikCheckbox>

                        <FormikSelect
                            style={{marginTop:'.5em'}}
                            disabled={values.set_creator_as_me}
                            name={'creator_tag_name'}
                            showSearch
                            loading={creatorTagsLoading}
                            defaultActiveFirstOption={false}
                            placeholder={t('search-or-add-new','Search or add new...')}
                            filterOption={false}
                            onSearch={onCreatorSearch}
                            onInputKeyDown={e => { if(e.keyCode == 13) setFieldValue('creator_tag_name', newCreatorTag)}}
                            notFoundContent={null}
                            allowClear
                            aria-label={'creator_tag_name'}
                        >
                            {creatorTagOptions}
                        </FormikSelect>
                    </FormItem>
                )

                const DescriptionForm = (
                    <FormItem name='description' extra={t('existing-text-will-be-overwritten','Existing text will be overwritten.')}>
                        <Input.TextArea name='description' autoComplete='off' aria-label={'description'}/>
                    </FormItem>
                )

                const EventForm = (
                    <FormItem name={'event_tag_name'} >
                        <EventTagSelect/>
                    </FormItem>
                )

                const TagFormTitle = (
                    <strong>Keywords {_.find(upload.tag_suggesters || [], {required: true}) && requirementTag('tag_suggesters')}</strong>
                )

                const TagForm = (
                    <FormItem name={'tag_names'}>
                        <TagSelect bulk upload={upload}/>

                        {!!upload.tag_suggesters?.length && (
                            <>
                                <FormikCheckbox.Group name='tag_names'>
                                    {upload.tag_suggesters.map((ts,i) => (
                                        <div key={ts.id} style={{margin: '1em 0'}}>
                                            <Typography.Text strong>{ts.name}: {ts.required && (upload.unmet_tag_suggester_ids?.indexOf(ts.id) != -1 ? <RequiredTag/> : <RequirementMetTag/>)}</Typography.Text>
                                            <br/>
                                            {_.compact(ts.tag_names).map(name => (
                                                <div key={name} className={'tag-suggester'}>
                                                    <FormikCheckbox key={name} value={name}>{name}</FormikCheckbox>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </FormikCheckbox.Group>
                                <br/>
                                <Typography.Text type={'secondary'}><em>{t('choose-all-that-apply','Choose all that apply.')}</em></Typography.Text>
                            </>
                        )}
                    </FormItem>
                )

                const RightsFormTitle = (
                    <strong>Rights {upload.contribution.require_rights_package && requirementTag('rights_packages')}</strong>
                )

                const RightsForm = (
                    <FormItem name={'rights_package_id'}>
                        <FormikRadio.Group
                            name={'rights_package_id'}
                            style={{width:'100%'}}
                        >
                            {upload.contribution.rights_packages.map(rp => (
                                <FormikRadio key={rp.id} value={rp.id} style={{display:'block'}}>
                                    <RightsPackage rightsPackage={rp} placement={'left'} style={{display:'inline', marginBottom:'.5em'}} showSummary/>
                                </FormikRadio>
                            ))}

                            <Radio key={'none'} value={0}>
                                <em>{t('none','None')}</em>
                            </Radio>
                        </FormikRadio.Group>

                        {values.rights_package_id != null && (
                            <Button
                                size={'small'}
                                icon={<CloseCircleOutlined/>}
                                onClick={()=> setFieldValue('rights_package_id', null)}
                                style={{marginTop:'.5em'}}
                            >
                                {t('button-clear-selection','Clear Selection')}
                            </Button>
                        )}
                    </FormItem>
                )

                return (
                    <>
                        {bulkJobId && (
                            <BulkJobProgress id={bulkJobId} onFinish={onFinish}/>
                        ) || (
                            <Form onSubmit={handleSubmit} layout="vertical" id={'upload-tag-all-form'}>
                                {isBrowser() && (
                                    <Row gutter={[16,16]}>
                                        <Col lg={6} xs={24}>
                                            {upload.contribution.asset_group?.type != 'StorageFolder' && upload.contribution.asset_group?.type != 'Lightbox' && (
                                                <Descriptions bordered size={'small'} column={1} style={{width:'100%', marginBottom:'.5em'}}>
                                                    <Descriptions.Item label={t('upload-title','Upload Title')} labelStyle={{width:150}}>
                                                        <Typography.Text editable={{onChange: onChangeUpload('name')}}>{uploadAttrs.name}</Typography.Text>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label={t('upload-notes','Upload Notes')}>
                                                        <Typography.Paragraph
                                                            editable={{onChange: onChangeUpload('note')}}
                                                        >
                                                            {uploadAttrs.note}
                                                        </Typography.Paragraph>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            )}

                                            <Card size={'small'} title={CreatorTitle}>
                                                {CreatorForm}
                                            </Card>
                                        </Col>

                                        <Col lg={6} xs={24}>
                                            <Space direction={'vertical'} style={{width:'100%'}}>
                                                <Card title={t('description','Description')} size={'small'}>
                                                    {DescriptionForm}
                                                </Card>

                                                <Card size={'small'} title={<strong><CalendarOutlined/> {t('event','Event')}</strong>}>
                                                    {EventForm}
                                                </Card>
                                            </Space>
                                        </Col>

                                        <Col lg={6} xs={24}>
                                            <Card size={'small'} title={TagFormTitle}>
                                                {TagForm}
                                            </Card>
                                        </Col>

                                        <Col lg={6} xs={24}>
                                            <Card size={'small'} title={RightsFormTitle}>
                                                {RightsForm}
                                            </Card>
                                        </Col>
                                    </Row>
                                )}

                                {isMobile() && (
                                    <Collapse>
                                        <Collapse.Panel
                                            key={'creator'}
                                            header={CreatorTitle}
                                        >
                                            {CreatorForm}
                                        </Collapse.Panel>

                                        <Collapse.Panel
                                            key={'description'}
                                            header={<>{t('description','Description')}</>}
                                        >
                                            {DescriptionForm}
                                        </Collapse.Panel>

                                        <Collapse.Panel
                                            key={'event'}
                                            header={<>{t('event','Event')}</>}
                                        >
                                            {EventForm}
                                        </Collapse.Panel>

                                        <Collapse.Panel
                                            key={'keywords'}
                                            header={TagFormTitle}
                                        >
                                            {TagForm}
                                        </Collapse.Panel>

                                        <Collapse.Panel
                                            key={'rights'}
                                            header={RightsFormTitle}
                                        >
                                            {RightsForm}
                                        </Collapse.Panel>
                                    </Collapse>

                                )}

                                <div style={isMobile() ? {marginBottom:'2em', marginTop:'1em'} : {marginTop:'1em'}}>
                                    <Popconfirm title={t('confirm-are-you-sure','Are you sure?')} onConfirm={clickSubmit} zIndex={1032}>
                                        {!selectedAssetIds.length ? (
                                            <Button icon={<SaveOutlined/>} loading={isSubmitting}  block={isMobile()} type={'primary'} ghost={!isMobile()}>{t('button-add-to-all','Add To All')}</Button>
                                        ) : (
                                            <Button icon={<SaveOutlined/>} loading={isSubmitting} block={isMobile()} type={'primary'} ghost={!isMobile()}>{t('add-to-selected','Add To All Selected')}</Button>
                                        )}
                                    </Popconfirm>
                                </div>
                            </Form>
                        )}
                    </>
                )
            }}
        </Formik>
    )

}

const useConnectGoogleDrive = (cb)=>{
    const currentOrg = useCurrentOrg()
    const refreshOrg = useRefreshOrg()

    const {dispatch} = useContext(SessionContext);

    const click = ()=>{
        const jwt = JSON.parse(localStorage.getItem('app-reducer'))['jwt']
        window.open(`/api/integrations/connect_google_drive?jwt=${jwt}&org_id=${currentOrg.id}`)

        window.googleDriveCallback = googleDriveAccessToken =>{
            console.log('googleDriveCallback', googleDriveAccessToken)
            dispatch({type:'org_updated', org: {...currentOrg, googleDriveAccessToken, hasGoogleDrive: true}})
            refreshOrg()

            cb && cb()
        }
    }

    return click
}

const ConnectGoogleDriveButton = ({cb, button})=>{
    const {t} = useTranslation();

    const click = useConnectGoogleDrive(cb)

    return button ? (
        <Button onClick={click} icon={<GoogleDriveIcon/>} ghost type={'primary'} block>{t('connect','Connect')} Google Drive</Button>
    ) : (
        <a onClick={click}><GoogleDriveIcon/> Connect Google Drive</a>
    )
}

export {ConnectGoogleDriveButton, useConnectGoogleDrive}

const GoogleDrivePicker = ({visible})=> {
    const {currentUpload} = useAssetGroupState()

    const [token, setToken] = useState()

    useEffect(()=>{
        if(!visible) return

        if(token) {
            handleOpenPicker(token)

        } else {
            api.post('/api/integrations/refresh_google_drive').then(res => {
                if(res.data.access_token) {
                    setToken(res.data.access_token)
                    handleOpenPicker(res.data.access_token)
                } else {
                    message.error(res.data.error)
                    // onCancel()
                }
            })
        }
    }, [visible])

    const [openPicker, files, authResponse] = useDrivePicker();
    const handleOpenPicker = token => {
        const customViews = [
            new google.picker.DocsView()
                .setMode(window.google.picker.DocsViewMode.LIST)
                .setIncludeFolders(true)
                .setSelectFolderEnabled(true),
        ];
        openPicker({
            appId: Config.googleDriveAppId,
            clientId: Config.googleDriveClientId,
            developerKey: Config.googleDriveDeveloperKey,
            disableDefaultView: true,
            token: token, // pass oauth token in case you already have one
            // showUploadView: true,
            // showUploadFolders: true,
            setIncludeFolders: true,
            setSelectFolderEnabled: true,
            supportDrives: true,
            multiselect: true,
            customViews: customViews, // custom view
        })
    }

    useEffect(() => {
        // do anything with the selected/uploaded files
        if(files){
            message.success('Starting Google Drive Upload!')
            const data = files.docs.map(item => _.pick(item, ['id', 'name', 'type']))
            console.log(data)

            api.post('/api/ingestions', {ingestion: {data, upload_id: currentUpload.id, type: 'GoogleDriveIngestion', notify: false}}).then(res => {
                console.log(res)
            })
        }
    }, [files])

    // useEffect(()=>{
    //     if(visible) {
    //         setTimeout(()=>{
    //             handleOpenPicker()
    //         }, 1000)
    //     }
    // }, [visible])

    return (
        <></>
    )
}

export {GoogleDrivePicker}

const ConnectDropboxButton = ({cb, button=false})=>{
    const {t} = useTranslation();

    const click = useConnectDropboxButton(cb)

    if(button) {
        return (
            <Button onClick={click} icon={<DropboxOutlined/>} ghost type={'primary'} block>{t('connect','Connect')} Dropbox</Button>
        )
    }

    return (
        <a onClick={click}><DropboxOutlined/> {t('connect','Connect')} Dropbox</a>
    )
}

const useConnectDropboxButton = cb =>{
    const currentOrg = useCurrentOrg()
    const refreshOrg = useRefreshOrg()

    const {dispatch} = useContext(SessionContext);

    const click = ()=>{
        const jwt = JSON.parse(localStorage.getItem('app-reducer'))['jwt']
        window.open(`/api/integrations/connect_dropbox?jwt=${jwt}&org_id=${currentOrg.id}`)

        window.dropboxCallback = dropbox_token =>{
            console.log('dropboxCallback', dropbox_token)
            dispatch({type:'org_updated', org: {...currentOrg, dropbox_token, hasDropbox: true}})
            refreshOrg()

            cb && cb()
        }
    }

    return click
}

export {ConnectDropboxButton}

const useConnectBoxButton = (cb)=> {
    const currentOrg = useCurrentOrg()
    const refreshOrg = useRefreshOrg()

    const {dispatch} = useContext(SessionContext);

    const click = ()=>{
        const jwt = JSON.parse(localStorage.getItem('app-reducer'))['jwt']
        window.open(`/api/integrations/connect_box?jwt=${jwt}&org_id=${currentOrg.id}`)

        window.boxCallback = box_token =>{
            console.log('boxCallback', box_token)
            dispatch({type:'org_updated', org: {...currentOrg, box_token, hasBox: true}})
            refreshOrg()

            cb && cb()
        }
    }

    return click
}

const ConnectBoxButton = ({cb, button=false})=>{
    const {t} = useTranslation();

    const click = useConnectBoxButton(cb)

    if(button) {
        return (
            <Button onClick={click} icon={<BoxIcon style={{marginRight:5}}/>} ghost type={'primary'} block>{t('connect','Connect')} Box.com</Button>
        )
    }

    return (
        <a onClick={click}><BoxIcon /> {t('connect','Connect')} Box.com</a>
    )
}

export {ConnectBoxButton, useConnectBoxButton}

const useDropboxChooserButton = ()=>{
    const {t} = useTranslation();
    const {currentUpload} = useAssetGroupState()

    const options = {
        multiselect: true,
        folderselect: true,
        linkType: 'preview',
        success: files => {
            console.log(files)
            message.success(t('message-starting-dropbox-upload','Starting Dropbox Upload!'))

            const data = files.map(item => _.pick(item, ['id', 'name', 'isDir']))

            api.post('/api/ingestions', {data, upload_id: currentUpload.id, type: 'DropboxIngestion', notify: false}).then(res => {
                console.log(res)
            })
        },
    }

    const chooser = useRef()

    return ()=> {
        if(chooser.current) return;

        chooser.current = Dropbox.choose(options);
    }
}

export {useDropboxChooserButton, useConnectDropboxButton}

const BoxFilePicker = ({visible, onCancel})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const {currentUpload} = useAssetGroupState()

    const choose = items => {
        // TODO: checkbox for notify
        // console.log(JSON.stringify(items, null, 2));

        message.success(t('message-starting-box-upload','Starting Box Upload!'))

        const data = items.map(item => _.pick(item, ['id', 'name', 'type']))

        api.post('/api/ingestions', {data, upload_id: currentUpload.id, type: 'BoxIngestion', notify: false}).then(res => {
            console.log(res)
        })

        onCancel()
    }

    const [token, setToken] = useState()

    useEffect(()=>{
        if(!visible) return

        api.post('/api/integrations/refresh_box').then(res => {
            if(res.data.access_token) {
                setToken(res.data.access_token)
            } else {
                message.error(res.data.error)
                onCancel()
            }
        })
    }, [visible])

    useEffect(()=>{
        if(!token) return;

        const contentPicker = new Box.ContentPicker();
        contentPicker.clearCache()

        contentPicker.addListener('choose', choose)
        contentPicker.addListener('cancel', onCancel)

        contentPicker.show('0', token, {
            container: '.container',
            chooseButtonLabel: t('select','Select'),
            type: 'file,folder',
            logoUrl: 'box',
            canSetShareAccess: false,
            autoFocus: true,
        })

        return ()=>{
            contentPicker.removeAllListeners()
        }
    }, [token])

    if(!currentOrg?.box_token?.access_token) return <></>

    return (
        (<Modal
            open={visible}
            onCancel={onCancel}
            width={'75%'}
            bodyStyle={{padding:0}}
            footer={null}
            title={t('select-assets-to-upload-from-box','Select Assets To Upload From Box.com')}
        >
            {!token && <LoadingOutlined style={{margin:'1em'}}/>}
            <div className={'container'} style={{height:'75vh'}}/>
        </Modal>)
    );
}

export {BoxFilePicker}

const FTPButton = ({upload, menuItem, visible, setVisible})=>{
    const {t} = useTranslation();

    return (<>
        {!menuItem && (
            <Button
                size={'small'}
                ghost
                type={'primary'}
                icon={<FtpIcon/>}
                onClick={()=> setVisible(true)}
                style={{marginLeft:'1em'}}
            >
                {t('button-sftp-upload','SFTP Upload')}...
            </Button>
        )}

        <Modal
            title={<><FtpIcon/> {t('sftp-upload-credentials','SFTP Upload Credentials')} <HelpPopover code={'upload-ftp-credentials'}/></>}
            open={visible}
            onCancel={()=> setVisible(false)}
            footer={null}
        >
            <VerticalSpace>
                <Descriptions bordered size={'small'} column={1} style={{width:'100%'}}>
                    <Descriptions.Item label={t('server','Server')}>
                        <Typography.Text code copyable>{Config.ftpServer}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('username','Username')}>
                        <Typography.Text code copyable>{upload.ftp_username}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('password','Password')}>
                        <Typography.Text code copyable>{upload.ftp_password}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('port','Port')}>
                        <Typography.Text code copyable>22</Typography.Text>
                    </Descriptions.Item>
                </Descriptions>

                <Tooltip title={upload.ftp_url}>
                    <Button type='primary' href={upload.ftp_url} ghost block icon={<ExportOutlined />} tabIndex={0}>{t('button-open-in-sftp-app','Open in SFTP App')}</Button>
                </Tooltip>
            </VerticalSpace>
        </Modal>
    </>);
}