import {Tooltip, Button, Collapse, Divider, Tag, Space, Popover, Flex} from "antd";
import {AppstoreAddOutlined, FolderOutlined, FormOutlined, ProjectOutlined} from "@ant-design/icons";
import AssetGroupChooser from "../manage/AssetGroupChooser";
import React, {useEffect, useCallback, useContext, useState} from "react";
import {useStorageState} from "react-storage-hooks";
import {AbilityContext} from "../helpers/Can";
import {useNavigate} from "react-router-dom-v5-compat";
import setTitle from "../helpers/setTitle";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import HelpPopover from "../HelpPopover";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";
import {SessionContext} from "../../contexts/SessionContext";
import {useAbility} from "@casl/react";
import {CollectionIcon, CollectionShareIcon, DeleteIcon, LibraryIcon, ProjectIcon, RefreshIcon, StorageFolderIcon} from "../helpers/icons";
import {OrgLink, useOrgPath} from "../helpers/OrgNavLink";
import useCurrentOrg from "../helpers/useCurrentOrg";
import useCurrentUser from "../helpers/useCurrentUser";
import User from "../helpers/User";
import VerticalSpace from "../helpers/VerticalSpace";
import {useTranslation} from "react-i18next";

export default ({collapsed})=>{
    const {t} = useTranslation();
    const ability = useAbility(AbilityContext);

    const navigate = useNavigate();

    const {state: sessionState} = useContext(SessionContext);
    const {currentOrg} = sessionState;
    const currentUser = useCurrentUser()

    const {
        currentAssetGroup, 
        collectionReloads, 
        lightboxReloads, 
        storageFolderReloads, 
        fullReloads, 
        collectionSharesReloads, 
        currentFederationOrg
    } = useAssetGroupState();

    const assetGroupDispatch = useAssetGroupDispatch();

    setTitle(currentAssetGroup?.name || 'Explore');

    const [activeSidebarKeys, setActiveSidebarKeys] = useStorageState(sessionStorage, `activeSidebarKeys`, []);

    const getPath = useOrgPath()

    // FIXME: slug may have changed from an edit elsewhere
    const selectCollection = useCallback((collection) => {
        const viewNone = collection == 'no-collection';

        if(typeof collection == 'string')
            assetGroupDispatch({
                type:'viewAllType',
                assetGroupType: viewNone ? 'NoCollection' :'Collection'
            });
        else if(currentAssetGroup?.id !== collection.id) assetGroupDispatch({type:'setCurrentAssetGroup', assetGroup: collection });

        console.log('selectCollection', collection)

        navigate(getPath(`/explore/collections/${(collection.id && assetGroupSlugs[collection.id]) || collection.slug || `view-${viewNone ? 'none' : 'all'}`}`));
    }, [currentOrg?.slug])

    const selectCollectionShare = useCallback((collectionShare) => {
        console.log(collectionShare)

        assetGroupDispatch({type:'setCurrentAssetGroup', assetGroup: collectionShare });

        if(collectionShare.sub_type === 'CollectionShare') {
            navigate(getPath(`/explore/shared-libraries/${collectionShare.slug}`));
        } else {
            navigate(getPath(`/explore/shared-libraries/${collectionShare.organization_slug}/${collectionShare.slug}`));
        }

    }, [currentOrg?.slug])

    const selectStorageFolder = useCallback((storageFolder) => {
        if(typeof storageFolder == 'string') assetGroupDispatch({type:'viewAllType', assetGroupType: 'StorageFolder'})
        else if(currentAssetGroup?.id !== storageFolder.id) assetGroupDispatch({type:'setCurrentAssetGroup', assetGroup: storageFolder });

        navigate(getPath(`/explore/folders/${assetGroupSlugs[storageFolder.id] || storageFolder.slug || 'view-all'}`));
    },[currentOrg?.slug])

    const selectLightbox = useCallback((lightbox) => {
        if(typeof lightbox == 'string') assetGroupDispatch({type:'viewAllType', assetGroupType: 'Lightbox'})
        else assetGroupDispatch({type:'setCurrentAssetGroup', assetGroup: lightbox});

        navigate(getPath(`/explore/projects/${assetGroupSlugs[lightbox.asset_group_id] || lightbox.slug || 'view-all'}`));
    },[currentOrg?.slug])

    const [refreshStorageFolders, setRefreshStorageFolders] = useState(0)
    const [refreshLibraries, setRefreshLibraries] = useState(0)
    const [refreshCollectionShares, setRefreshCollectionShares] = useState(0)
    const [refreshProjects, setRefreshProjects] = useState(0)

    useEffect(()=>{
        if(collectionReloads) setRefreshLibraries(collectionReloads)
    }, [collectionReloads])

    useEffect(()=>{
        if(lightboxReloads) setRefreshProjects(lightboxReloads)
    }, [lightboxReloads])

    useEffect(()=>{
        if(storageFolderReloads) setRefreshStorageFolders(storageFolderReloads)
    }, [storageFolderReloads])

    useEffect(()=>{
        if(collectionSharesReloads) setRefreshCollectionShares(collectionSharesReloads)
    }, [collectionSharesReloads])

    // Org switching:
    useEffect(()=> {
        if(!fullReloads) return;

        setRefreshStorageFolders(refreshStorageFolders + 1)
        setRefreshCollectionShares(refreshCollectionShares + 1)
        setRefreshLibraries(refreshLibraries + 1)
        setRefreshProjects(refreshProjects + 1)
    }, [fullReloads])

    const refresh = (e, type)=>{
        e.stopPropagation()
        e.preventDefault()

        switch(type) {
            case 'storage':
                return setRefreshStorageFolders(refreshStorageFolders + 1)
            case 'libraries':
                return setRefreshLibraries(refreshLibraries + 1)
            case 'collection-shares':
                return setRefreshCollectionShares(refreshCollectionShares + 1)
            case 'projects':
                return setRefreshProjects(refreshProjects + 1)
        }
    }

    const fileVaultBg = '#ddd'

    const StorageFoldersTitle = (
        <Space><StorageFolderIcon/><strong>{t('file-vault','File Vault')}</strong></Space>
    )

    const StorageFoldersExtra = (
        <Space size={2}>
            <Tooltip title={t('tooltip.refresh','Refresh')}>
                <Button size='small' onClick={(e)=> refresh(e, 'storage')} type={'text'} shape={'circle'} id={'refresh-storage-folders-btn'}>
                    <RefreshIcon/>
                </Button>
            </Tooltip>

            <HelpPopover code={'storage-folders-collapse-header'} generalAndAdmin/>
        </Space>
    )

    const StorageFoldersContent = (
        <>
            <AssetGroupChooser
                showAggs
                enableAssetDrop
                organizerSelection
                type={'storage_folders'}
                setSelectedGroup={selectStorageFolder}
                current={currentAssetGroup}
                draggable={false}
                reset={refreshStorageFolders}
                sessionKey={'explore'}
            />

            <Divider style={{margin:'.5em 0'}}/>

            <ViewTrashButton reset={refreshStorageFolders}/>
        </>
    )

    const CollectionSharesTitle = <Space><CollectionShareIcon/><strong>{t('shared-libraries','Shared Libraries')}</strong></Space>;
    const CollectionSharesExtra = (
        <Space size={2}>
            <Tooltip title={t('tooltip.refresh', 'Refresh')}>
                <Button size='small' onClick={(e) => refresh(e, 'collection-shares')} type={'text'} shape={'circle'}>
                    <RefreshIcon/>
                </Button>
            </Tooltip>

            <HelpPopover code={'collection-shares-collapse-header'} generalAndAdmin id={'collection-shares-help-popover'}/>
        </Space>
    )
    const CollectionSharesContent = (
        <AssetGroupChooser
            organizerSelection
            showAggs
            hideRearrangeButton
            type={'collection_shares'}
            setSelectedGroup={selectCollectionShare}
            current={currentAssetGroup || currentFederationOrg}
            draggable={false}
            reset={refreshCollectionShares}
            sessionKey={'explore-collection-shares'}
            empty={<em style={{color:'grey'}}>{t('no-collection-shares','None yet...')}</em>}
        />
    )


    const CollectionsTitle = <Space><LibraryIcon/><strong>{t('libraries','Libraries')}</strong></Space>;
    const CollectionsExtra = (
        <Space size={2}>
            <Tooltip title={t('tooltip.refresh', 'Refresh')}>
                <Button size='small' onClick={(e) => refresh(e, 'libraries')} type={'text'} shape={'circle'}>
                    <RefreshIcon/>
                </Button>
            </Tooltip>

            <HelpPopover code={'libraries-collapse-header'} generalAndAdmin id={'libraries-help-popover'}/>
        </Space>
    )
    const CollectionsContent = (
        <>
            <AssetGroupChooser
                showAggs
                enableAssetDrop
                organizerSelection
                type={'collections'}
                setSelectedGroup={selectCollection}
                current={currentAssetGroup}
                sandbox={'all'}
                draggable={false}
                reset={refreshLibraries}
                sessionKey={'explore'}
            />

            {currentOrg?.show_shared_libraries && (
                <div id={'shared-libraries-panel'}>
                    <Divider style={{margin:'1em 0'}}/>
                    <VerticalSpace size={'middle'}>
                        <Flex justify={'space-between'}>
                            {CollectionSharesTitle}
                            {CollectionSharesExtra}
                        </Flex>
                        {CollectionSharesContent}
                    </VerticalSpace>
                </div>
            )}
        </>
    )

    const ProjectsTitle = (
        <Space>
            {currentUser ? (
                <User user={currentUser} iconOnly noPopover size={25}/>
            ) : (
                <ProjectIcon/>
            )}

            <strong>{t('my-projects', 'My Projects')}</strong>
        </Space>
    )
    const ProjectsExtra = (
        <Space size={2}>
            <Tooltip title={t('tooltip.refresh', 'Refresh')}>
                <Button size='small' onClick={(e) => refresh(e, 'projects')} type={'text'} shape={'circle'}>
                    <RefreshIcon/>
                </Button>
            </Tooltip>

            <HelpPopover code={'projects-collapse-header'} generalAndAdmin/>
        </Space>
    )
    const ProjectsContent = (
        <VerticalSpace>
            {!['admin', 'global_content', 'global_library'].includes(currentOrg?.role_level) && currentOrg?.enable_access_requests && (
                <Collapse>
                    <Collapse.Panel header={<><FormOutlined/> <span>{t('access-requests', 'Access Requests')}</span></>}>
                        <Workboxes reset={refreshProjects}/>
                    </Collapse.Panel>
                </Collapse>
            )}

            {ability.can('show', 'Lightbox') && (
                <AssetGroupChooser
                    showAggs
                    enableAssetDrop
                    organizerSelection
                    type={'lightboxes'}
                    setSelectedGroup={selectLightbox}
                    current={currentAssetGroup}
                    draggable={false}
                    reset={refreshProjects}
                    sessionKey={'explore'}
                    checkableIf={lb => lb.sub_type != 'folder'}
                />
            ) || <em>Please sign up to create Projects.</em>}
        </VerticalSpace>
    )

    const maxHeight = '80vh'

    if(collapsed) {
        const style = {maxHeight, overflow:'scroll', padding:5};
        return (
            <VerticalSpace size={2}>
                {ability.can('show', 'StorageFolder') && (
                    <Popover
                        getPopupContainer={e => e.parentElement}
                        trigger={['hover','click']}
                        content={<div style={style}>{StorageFoldersContent}</div> }
                        title={<Flex justify={'space-between'}>{StorageFoldersTitle} {StorageFoldersExtra}</Flex>}
                        placement={'right'}
                        overlayStyle={{minWidth:300}}
                    >
                        <Button shape={'circle'} icon={<StorageFolderIcon />} type={'text'} aria-label={t('file-vault','File Vault')} className={'sidebar-btn'}/>
                    </Popover>
                )}

                <Popover
                    trigger={['hover','click']}
                    getPopupContainer={e => e.parentElement}
                    content={<div style={style}>{CollectionsContent}</div> }
                    title={<Flex justify={'space-between'}>{CollectionsTitle} {CollectionsExtra}</Flex>}
                    placement={'right'}
                    overlayStyle={{minWidth:300}}
                >
                    <Button shape={'circle'} icon={<LibraryIcon/>} type={'text'} aria-label={t('collections','Collections')} className={'sidebar-btn'}/>
                </Popover>

                <Popover
                    trigger={['hover','click']}
                    getPopupContainer={e => e.parentElement}
                    content={<div style={style}>{ProjectsContent}</div> }
                    title={<Flex justify={'space-between'}>{ProjectsTitle} {ProjectsExtra}</Flex>}
                    placement={'right'}
                    overlayStyle={{minWidth:300}}
                >
                    <Button shape={'circle'} icon={<ProjectIcon />} type={'text'} aria-label={t('projects','Projects')} className={'sidebar-btn'}/>
                </Popover>
            </VerticalSpace>
        )
    }

    return (
        <>
            <Collapse
                activeKey={activeSidebarKeys}
                onChange={keys => setActiveSidebarKeys(keys)}
                bordered
                style={{backgroundColor: fileVaultBg, display: ability.can('show', 'StorageFolder') ? '' : 'none', marginBottom: 16}}
                items={[
                    {
                        key: 'storage-folders-panel',
                        id: 'storage-folders-panel',
                        label: StorageFoldersTitle,
                        extra: StorageFoldersExtra,
                        children: StorageFoldersContent
                    }
                ]}
            />

            <Collapse
                activeKey={activeSidebarKeys}
                onChange={keys => setActiveSidebarKeys(keys)}
                bordered
                items={_.compact([
                    {
                        key: 'collections-panel',
                        id: 'collections-panel',
                        label: CollectionsTitle,
                        extra: CollectionsExtra,
                        children: CollectionsContent
                    },
                    {
                        key: 'my-projects-panel',
                        id: 'my-projects-panel',
                        label: ProjectsTitle,
                        extra: ProjectsExtra,
                        children: ProjectsContent
                    }
                ])}
            />
        </>
    )
}

const ViewTrashButton = ({reset})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const {viewAllType} = useAssetGroupState();

    const [info, setInfo] = useState()

    useEffect(()=>{
        if(!currentOrg) return

        api('/api/assets/trashed').then(res => setInfo(res.data))
    }, [currentOrg?.id, reset])

    return (
        <OrgLink to={'/explore/trash'} style={{marginLeft:'.5em', color: viewAllType === 'Trash' ? 'auto' : 'grey'}}>
            <DeleteIcon/> {t('trash','Trash')}

            {info && (
                <span style={{marginLeft:'.5em'}}>
                    <Tag>{info.count}</Tag>
                </span>
            )}
        </OrgLink>
    )
}

const Workboxes = ({reset})=>{
    const currentOrg = useCurrentOrg()
    const currentUser = useCurrentUser()

    const navigate = useNavigate();
    const getPath = useOrgPath()

    const {currentAssetGroup} = useAssetGroupState();

    // TODO: check if we should render the panel?
    useEffect(()=>{
        if(!currentOrg) return
        // api('/api/access_requests')

    }, [currentOrg?.id, currentUser?.id])

    const selectAccessRequest = useCallback(accessRequest => {
        navigate(getPath(`/access-requests/${accessRequest.access_request_id}`));
    },[currentOrg?.slug])

    return (
        <div id={'workboxes'}>
            <AssetGroupChooser
                type={'access_requests'}
                setSelectedGroup={selectAccessRequest}
                showAggs
                enableAssetDrop
                draggable={false}
                hideAddButton
                hideViewAll
                current={{...currentAssetGroup, type: 'AccessRequest'}}
                hideRearrangeButton
                selectableIf={node => !!node.slug}
                hideCountIf={node => !node.slug}
                hideInfo
                showBadge
                reset={reset}
            />
        </div>
    )
}